<template lang="html">
 <div class="prepayContent">
      <div class="list" @click="contractShow = true">
        <div class="left">
          合同选择
        </div>
        <div class="right">
          <span>{{contract_name}}</span>
          <van-icon name="arrow-down" />
        </div>
      </div>
    </div>
    <div class="times" v-if="detail">
      <div>
        合同开始时间:<span>{{start}}</span>
      </div>
      <div>合同结束时间:<span>{{end}}</span></div>
    </div>
  <div class="prepay" v-if="detail">
    <div class="prepayBox">
      <div class="list" v-for="(item, index) in detail.before" :key="index">
        <div class="name">
          商铺号：{{item.merchantsStallNumber}}
        </div>
        <div class="box">
          <div class="left">
            租金到期时间：{{item.beforeMerchantsStallEndTime}}
          </div>
          <div class="right">
            {{item.merchantsStallPrice}}元/月
          </div>
        </div>
      </div>
    </div>
    <div class="prepayContent">
      <div class="list" @click="show = true">
        <div class="left">
          预缴时间：
        </div>
        <div class="right">
          <span>{{prepayName}}</span>
          <van-icon name="arrow-down" />
        </div>
      </div>
      <div class="list">
        <div class="left">
          缴纳金额：
        </div>
        <div class="right">
          <div class="tag">
            ￥{{detail.beforehandMoney.money || 0}}
          </div>
        </div>
      </div>
    </div>
    <div class="prepayBox">
      <div class="list" v-for="(item, index) in detail.after" :key="index">
        <div class="name">
          商铺号：{{item.merchantsStallNumber}}
        </div>
        <div class="box">
          <div class="left">
            租金到期时间：<span>{{item.afterMerchantsStallEndTime}}</span>
          </div>
          <div class="right">
            {{item.merchantsStallPrice}}元/月
          </div>
        </div>
      </div>
    </div>
    <span class="tips">注：预缴月数不能超出合同期限</span>
    <div class="btn">
      <van-button round type="primary" @click="confirm">确认预缴</van-button>
    </div>
    <van-action-sheet v-model:show="show" title="请选择预缴月数" :actions="actions" @select="onSelect" />
  </div>
      <van-action-sheet v-model:show="contractShow" title="选择合同" :actions="contract_list" @select="contractSelect" />
</template>

<script>
  export default {
    name: 'prepay',
    data() {
      return {
        show: false,
        prepayName: '一个月',
        prepayValue: 1,
        detail: null,
        userInfo: null,
        contract_list: [],
        contract_name: '',
        contract_id: '',
        contractShow: false,
        start: '',
        end: '',
        actions: [
          {
            name: '一个月',
            value: 1
          },
          {
            name: '二个月',
            value: 2
          },
          {
            name: '三个月',
            value: 3
          },
          {
            name: '四个月',
            value: 4
          },
          {
            name: '五个月',
            value: 5
          },
          {
            name: '六个月',
            value: 6
          },
          {
            name: '七个月',
            value: 7
          },
          {
            name: '八个月',
            value: 8
          },
          {
            name: '九个月',
            value: 9
          },
          {
            name: '十个月',
            value: 10
          },
          {
            name: '十一个月',
            value: 11
          },
          {
            name: '十二个月',
            value: 12
          }
        ]
      };
    },
    created() {
      this.userInfo = JSON.parse(window.localStorage.getItem('propertyUserInfo'));
      // this.getDetail();
      this.getContract();
      this.contractShow = true;
    },
    methods: {
      getDetail() {
        this.$require.HttpPost('/h5/water_electricity_gas_rent/beforehandDiscount', { beforehand_month: this.prepayValue, contract_id: this.contract_id }).then(res => {
          this.detail = res.data;
          this.prepayValue = res.data.beforehandMoney.month;
          switch (res.data.beforehandMoney.month) {
            case 1:
              this.prepayName = '一个月';
              break;
            case 2:
              this.prepayName = '二个月';
              break;
            case 3:
              this.prepayName = '三个月';
              break;
            case 4:
              this.prepayName = '四个月';
              break;
            case 5:
              this.prepayName = '五个月';
              break;
            case 6:
              this.prepayName = '六个月';
              break;
            case 7:
              this.prepayName = '七个月';
              break;
            case 8:
              this.prepayName = '八个月';
              break;
            case 9:
              this.prepayName = '九个月';
              break;
            case 10:
              this.prepayName = '十个月';
              break;
            case 11:
              this.prepayName = '十一个月';
              break;
            case 12:
              this.prepayName = '十二个月';
              break;
            default:
              this.prepayName = '';
          }
        });
      },
      getContract() {
           this.$require.HttpPost('/h5/contract/contractNo').then(res => {
        if (res.data) {
          res.data.forEach(item => {
            this.contract_list.push({
 name: item.contract_no, value: item.contract_id, start: item.contract_start, end: item.contract_end
});
          });
          this.merchants_stall_list = res.data;
          // console.log(this.contract_list);
        }
      });
      },
      onSelect(event) {
        this.prepayName = event.name;
        this.prepayValue = event.value;
        this.show = false;
        this.getDetail();
      },
      contractSelect(e) {
        console.log(e);
        this.start = e.start;
        this.end = e.end;
        this.contract_name = e.name;
         this.contract_id = e.value;
         this.getDetail();
         this.contractShow = false;
      },
      confirm() {
          this.beforehand();
      },
      beforehand() {
        this.$require.HttpPost('/h5/water_electricity_gas_rent/addRentBillOfflineANDBill', { beforehand_month: this.prepayValue, bill_key: 40, contract_id: this.contract_id }).then(res => {
          this.$require.HttpPost('/h5/water_electricity_gas_rent/changeMessageRent', { bill_offline_no: res.data }).then(() => {
            this.$toast('缴费成功');
            setTimeout(() => {
              this.$router.go(-1);
            }, 500);
          });
        });
      }
    }
  };
</script>

<style lang="scss" scoped>
  .times{
    font-size: 25px;
    color: #000000;
    padding: 0 25px;
    // font-weight: bolder;
    span{
      font-size: 20px;
      color: #999999;
      margin-left:15px
    }
  }
.prepayContent {

      margin: 80px 0;
      .list {
        padding: 0 25px;
        display: flex;
        justify-content: space-between;
        font-size: 32px;
        color: #000000;
        margin-top: 40px;
        &:first-child {
          margin-top: 0;
        }
        .right {
          font-size: 28px;
          font-family: PingFang-SC-Bold;
          font-weight: bold;
          color: #85C226;
          .tag {
            color: #F8C301;
          }
        }
      }
    }
  .prepay {
    padding: 0 24px;
    .prepayBox {
      background: #FFFFFF;
      box-shadow: 0px 0px 25px 0px rgba(57, 57, 57, 0.06);
      border-radius: 30px;
      margin-top: 30px;
      padding: 40px 0;
      .list {
        margin-top: 20px;
        &:first-child {
          margin-top: 0;
        }
        .name {
          position: relative;
          font-size: 28px;
          color: #666666;
          padding-left: 30px;
          &:before {
            content: ' ';
            width: 18px;
            height: 14px;
            background: #85C226;
            border-radius: 0px 7px 7px 0px;
            position: absolute;
            left: 0;
            top: 7px;
          }
        }
        .box {
          padding-left: 30px;
          padding-right: 55px;
          display: flex;
          justify-content: space-between;
          color: #000000;
          font-size: 28px;
          .left {
            span {
              color: #85C226;
            }
          }
        }
      }
    }
    .prepayContent {
      margin: 80px 0;
      .list {
        padding: 0 25px;
        display: flex;
        justify-content: space-between;
        font-size: 32px;
        color: #000000;
        margin-top: 40px;
        &:first-child {
          margin-top: 0;
        }
        .right {
          font-size: 28px;
          font-family: PingFang-SC-Bold;
          font-weight: bold;
          color: #85C226;
          .tag {
            color: #F8C301;
          }
        }
      }
    }
    .btn {
      width: 468px;
      height: 96px;
      margin: 50px auto 0 auto;
    }
    .tips{
      font-size: 20px;
      color: #999999;
    }
  }
</style>
